.accordion {
    --icon-size: 16px;
    --gap: 15px;
    --padding: 18px;
}

.accordion__content {
    margin: 0;
}

.accordion__label {
    align-items: baseline;
    background: var(--white);
    border-radius: var(--module-border-radius);
    cursor: pointer;
    display: flex;
    gap: var(--gap);
    padding: var(--padding);
    position: relative;
}

.accordion__label:focus,
.accordion__label:hover {
    background: var(--white);
}

.accordion__label[aria-expanded=true] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.accordion__label + .accordion__body--hidden {
    margin-bottom: 0;
}

.accordion__icon {
    fill: var(--peppermint-700);
    flex: 0 0 auto;
    height: var(--icon-size);
    transform: translateY(2px);
    width: var(--icon-size);
}

.no-js .accordion__icon {
    display: none;
}

.accordion__head {
    @mixin font-hanken;
    color: var(--peppermint-700);
    font-size: 17px;
    font-weight: 700;
    margin: 0;
}

.accordion__body {
    background: var(--white);
    margin: 0 0 .4em;
}

.accordion__body[aria-hidden=false] {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.js .accordion__body {
    display: none;
}

.accordion--initialized .accordion__body {
    display: block;
}

.js .accordion__body--hidden {
    display: none;
}

.accordion__text {
    padding: 0 var(--padding) var(--padding) calc(var(--padding) + var(--icon-size) + var(--gap));
}

.accordion__text p {
    color: var(--peppermint-700);
    line-height: 1.2;
}

.accordion__body--hidden + .accordion__label {
    margin-top: .4em;
}

.accordion--centered .accordion__headline {
    color: var(--peppermint-900);
    text-align: center;
}
