.card {
    text-align: center;
}

.card__head {
    @mixin font-hanken;
    color: var(--peppermint-700);
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 0;
    transition: color 0.3s ease;
}

.card__text {
    color: var(--peppermint-700);
    margin: 10px 0 0;
    transition: color 0.3s ease;
}

.card__link {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    display: block;
    outline: 0;
    text-decoration: none;
    transition: border-color 0.3s ease;

    &:focus,
    &:hover {
        border-color: var(--peppermint-700);
    }

    &:focus .card__icon--flip svg:nth-child(1),
    &:hover .card__icon--flip svg:nth-child(1) {
        opacity: 0;
    }

    &:focus .card__icon--flip svg:nth-child(2),
    &:hover .card__icon--flip svg:nth-child(2) {
        opacity: 1;
    }
}

.card__icon {
    height: 80px;
    margin: 0 auto;
    position: relative;
    width: 80px;

    svg {
        height: 80px;
        inset: 0;
        opacity: 1;
        position: absolute;
        transition: opacity 0.3s ease;
        width: 80px;
    }

    svg:nth-child(2) {
        opacity: 0;
    }
}

@media screen and (max-width: 799px) {
    .card__link {
        padding: 25px;
    }
}

@media screen and (min-width: 800px) {
    .card__link {
        height: 100%;
        padding: 40px;
    }
}
