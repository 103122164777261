.message {
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 2em;
    padding: 1.2em 1.2em 1.2em 3em;
    position: relative;
    text-align: left;
}

.message + .message {
    margin-top: -1em;
}

.message__error-list {
    margin-bottom: 0;
}

.message__error-list li {
    margin-bottom: .3em;
}

.message__error-list a,
.message__error-link {
    display: inline;
}

.message__error-link,
.message__error-link:visited,
.message__error-link:hover,
.message__error-link:focus,
.message__error-link:active {
    color: inherit;
    text-decoration: none;
}

.message__error-link:hover,
.message__error-link:focus,
.message__error-link:active {
    text-decoration: underline;
}

.message--error {
    color: var(--red-600);
}

.message--info {
    color: var(--gray-900);
}

.message--success {
    color: var(--peppermint-700);
}

.message__icon-wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3em;
}

.message__icon {
    fill: var(--peppermint-700);
    height: 1.4em;
    width: 1.4em;
}

.message__text {
    margin: 0;
}

.message__text + .message__text {
    margin-top: .5em;
}
