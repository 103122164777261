.form {
    color: var(--black);
}

.form__header {
    margin-bottom: 3em;
}

.form__row {
    display: flex;
    gap: 30px;
    margin-bottom: 0;
}

.form__row--margin {
    margin: 0.5em 0 0.8em !important;
}

.form__row--margin-bottom {
    margin-bottom: 1.35em;
}

.form__row--half-margin {
    margin-bottom: .667em;
}

.form__column {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.form__column--col {
    flex-direction: column;
    gap: 12px;
}

@media screen and (max-width: 659px) {
    .form__row {
        flex-direction: column;
    }
}

@media screen and (min-width: 660px) {
    .form__column--half {
        width: 50%;
    }

    .form__column--short {
        width: 33.33%;
    }

    .form__column--long {
        width: 66.66%;
    }

    .form__column--date {
        max-width: 20.5rem;
        width: 100%;
    }
}

.form__no-js-hint {
    /* used for hint texts that explain dependencies between fields, that are normally set by js
    f.e. "Fill in your birthdate, if you don't have your insurance number" */
    display: none;
}

.no-js .form__no-js-hint {
    display: inline-block;
}

.form__column-seperator {
    /* for text seperators between columns f.e. '/' */
    padding: .6115em 0;
}

.form__column--buttons {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.form__column--buttons button,
.form__column--buttons a,
.form__column--buttons input,
.form__column--buttons input[type="submit"] {
    text-align: center;
    width: 45%;
}

.form__column--width-buttons {
    flex-direction: row;
}

.form__column--width-buttons a,
.form__column--width-buttons button,
.form__column--width-buttons input,
.form__column--width-buttons input[type="submit"],
.form__column--width-buttons .p-button {
    margin: 0 0 1em;
    width: 100%;
}

.form__column--width-buttons a:last-child,
.form__column--width-buttons input:last-child,
.form__column--width-buttons input[type="submit"]:last-child,
.form__column--width-buttons button:last-child,
.form__column--width-buttons .p-button:last-child {
    margin-bottom: 0;
}

@media screen and (min-width: 660px) {
    .form__column--buttons {
        justify-content: flex-start;
    }

    .form__column--width-buttons {
        flex-direction: row-reverse;
    }

    .form__column--buttons a,
    .form__column--buttons button,
    .form__column--buttons input,
    .form__column--buttons input[type="submit"],
    .form__column--buttons .p-button {
        margin-left: 1em;
        width: auto;
    }

    .form__column--width-buttons a,
    .form__column--width-buttons button,
    .form__column--width-buttons input,
    .form__column--width-buttons input[type="submit"],
    .form__column--width-buttons .p-button {
        margin-bottom: 0;
    }
}

.form__option {
    height: 100%;
    position: relative;
}

.form__custom-checkbox,
.form__checkbox,
.form__custom-radio,
.form__radio {
    display: block;
    font-size: 1.143em; /* 16px320, 18px@1200 */
    height: 1.143em; /* ~ 18px 1200 */
    left: 0;
    padding: 0;
    position: absolute;
    width: 1.143em;  /* ~ 18px 1200 */
}

.form__custom-checkbox.error {
    border: 3px solid var(--red-600);
}

.form__custom-radio,
.form__radio {
    top: 1px; /* border-width */
}

.form__checkbox,
.form__radio {
    appearance: none;
    background: none;
    line-height: normal;
}

.form__custom-checkbox,
.form__custom-radio {
    padding: 0;
    position: absolute;
}

.form__custom-checkbox {
    background: var(--gray-50);
    border: 1px solid var(--gray-300);
    border-radius: 2px;
}

.form__checkbox:checked + .form__custom-checkbox::before {
    background: var(--gray-50) url("../img/icons/checkmark-green.svg") center no-repeat;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
}

.form__checkbox:disabled {
    opacity: 0;
}

.form__checkbox:disabled + .form__custom-checkbox {
    background: var(--white);
    border: 3px solid var(--gray-300);
}

.form__checkbox:disabled:checked + .form__custom-checkbox::before {
    background: var(--gray-50) url("../img/icons/checkmark-grey.svg") center no-repeat;
}

.form__custom-radio {
    background: var(--gray-50) url("../img/icons/radio.svg") center no-repeat;
}

.form__radio:checked + .form__custom-radio {
    background: var(--gray-50) url("../img/icons/radio-selected.svg") center no-repeat;
}

.form__radio:disabled {
    opacity: 0; /* for ff --> otherwise grey radio button shines through custom radio */
}

.form__radio:disabled + .form__custom-radio {
    opacity: .3;
}

.form__radio:disabled:checked + .form__custom-radio {
    background: var(--gray-50) url("../img/icons/radio-selected-disabled.svg") center no-repeat;
}

.form__fieldset {
    margin: 0;
}

.form__fieldset--small-margin {
    margin-bottom: 30px;
}

.form__fieldset--medium-margin {
    margin-bottom: 50px;
}

.form__label {
    line-height: 1.4;
    margin: 0;
}

.form__label--is-disabled {
    color: var(--scale);
}

.form__label--small {
    font-size: 16px;
}

.form__label--middle {
    font-size: 18px;
}

.form__label--big {
    line-height: 1.4;
}

.form__label--radio {
    padding-left: 30px;
}

.form__label--input {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}

.form__legend {
    padding: 0;
}

.form__legend--large {
    font-size: 28px;
    margin-bottom: 25px;
    width: 100%;
}

.form__legend--small {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
}

.form__require::after {
    content: "*";
}

.form__input {
    background: var(--gray-50);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    transition: background .3s ease, border-color .3s ease;

    &::placeholder {
        @mixin font-hanken;
        color: var(--gray-400);
        font-size: 18px;
    }

    &:focus,
    &:hover {
        border-color: var(--peppermint-700);
        outline: 0;
    }
}

.form__input--select {
    background: var(--gray-50) url("../img/icons/arrow-down-grey.svg") no-repeat right .5em center;
    background-size: 1.3em;
}

fieldset.error > .form__legend--small {
    padding: 0 .5em;
}

fieldset.error > legend.u-visually-hidden + .form__row {
    /* margin for fieldset with error class and hidden legend */
    margin-top: 1em;
}

textarea.form__validation-field,
textarea.form__validation-field:disabled,
input[type="text"].form__validation-field,
input[type="text"].form__validation-field:disabled {
    background: none;
    border: 0;
    color: var(--black);
    font-weight: normal;
    min-height: 0;
    opacity: 1;
    padding: 0;
    /* stylelint-disable */
    /* ios hack for setting the color for disabled inputs: http://stackoverflow.com/questions/262158/disabled-input-text-color */
    -webkit-text-fill-color: var(--black);
    /* stylelint-enable */
}

textarea.form__validation-field:disabled {
    resize: none;
}

.form__extra {
    color: var(--black);
    flex: 0 0 100%;
    font-size: 0.9rem;
    font-weight: normal;
}

.form__hint {
    color: var(--gray-700);
    font-size: 14px;
}

.form__hint--error {
    color: var(--red-600);
}

input.error + .form__hint--error {
    display: block;
}

.form__euro-field {
    position: relative;
    width: 100%;
}

.form__euro-field input {
    padding-right: 1em;
}

.form__euro-field::before {
    bottom: 2px; /* 2px border width */
    color: var(--gray-400);
    content: "€";
    display: block;
    padding: 6px;
    position: absolute;
    right: calc(.5em + 2px);  /* 2px border width */
    text-align: right;
    width: 1em;
}

.form__euro-field--disabled::before {
    color: var(--gray-300);
}
