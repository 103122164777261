.p-module--blue {
    background-color: var(--bright-200);
}

.p-module--grey {
    background-color: var(--scale);
}

.p-module--orange {
    background-color: var(--orange-200);
}

.p-module--white {
    background-color: var(--white);
}

.p-module--yellow {
    background-color: var(--yellow-light);
}
