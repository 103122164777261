.call-to-action__headline {
    color: var(--peppermint-900);
    margin-bottom: 25px;
}

.call-to-action__body {
    border-radius: 20px;
}

.call-to-action__body:not(.p-module--scale) {
    padding: 40px;

    .header--landing & {
        padding: 0;
    }
}

.call-to-action__text > p {
    margin-bottom: 1.5em;
}

.call-to-action__link-list {
    display: flex;
    flex-wrap: wrap;
}

.call-to-action--centered {
    text-align: center;

    .call-to-action__text {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .call-to-action__text > p {
        max-width: 70ch;
    }

    .call-to-action__link-list {
        justify-content: center;
    }
}

@media screen and (max-width: 799px) {
    .call-to-action__link-list {
        gap: 15px;
    }
}

@media screen and (min-width: 800px) {
    .call-to-action__link-list {
        gap: 30px;
    }
}
