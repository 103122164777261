.image-gallery__progressbar {
    background-color: var(--gray-400);
    display: block;
    height: 5px;
    overflow: hidden;
    width: 100%;
    z-index: 1;
}

.image-gallery__progressbar > div {
    background-color: var(--peppermint-700);
    height: 100%;
    transition: all .75s ease;
}

.image-gallery__caption-text {
    display: inline-block;
    font-size: .875em;
    margin: .75em 0;
}

.actions {
    align-items: center;
    display: flex;
    font-size: 1.5em;
    height: 40px;
    justify-content: space-between;
    left: 0;
    margin-top: -20px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
}

.actions > span {
    cursor: pointer;
    transition: all 250ms;
}

.actions > span.prev {
    margin-left: 10px;
}

.actions > span.next {
    margin-right: 10px;
}

.image-gallery .prev,
.image-gallery .next {
    background-color: var(--peppermint-700);
    border: 0;
    border-radius: 50%;
    color: var(--white);
    height: 40px;
    opacity: 1;
    padding: 0;
    width: 40px;
}

.image-gallery .prev {
    margin-left: 10px;
}

.image-gallery .next {
    margin-right: 10px;
}

.image-gallery__nav-icon {
    display: inline-block;
    fill: var(--white);
    height: 20px;
    left: 0;
    margin-top: 8px;
}

.image-gallery__nav-icon--prev {
    margin-left: -.07em;
}

.image-gallery__nav-icon--next {
    margin-left: .07em;
}

.image-gallery .prev:hover,
.image-gallery .next:hover {
    background-color: var(--peppermint-500);
    opacity: 1;
}

.image-gallery .fas {
    margin-left: .2em;
}

.image-gallery__body {
    align-items: center;
    display: flex;
    height: calc((200vw - 5rem) / 3);
    justify-content: center;
    position: relative;
    width: 100%;
}

.image-gallery__scene-list {
    height: 100%;
    position: relative;
    width: 100%;
}

.image-gallery__scene-body {
    align-items: center;
    display: flex;
    height: calc((200vw - 5rem) / 3);
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width: 800px) {
    .image-gallery__scene-body {
        height: calc((133vw - 5rem) / 3);
    }
}

@media screen and (min-width: 1200px) {
    .image-gallery__scene-body {
        height: 490px;
    }
}

.image-gallery__frame {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(12px) saturate(.5) brightness(95%);
    height: 110%;
    transform: scale(1.03);
    transition: background .5s ease;
    width: 100%;
}

.image-gallery__image-wrapper {
    align-items: center;
    box-shadow: 0 0 45px #0006;
    display: flex;
    inset: 0;
    justify-content: center;
    position: absolute;
}

.image-gallery__image-wrapper img {
    height: 105%;
    max-height: 105%;
    max-width: 106%;
}

.image-gallery__paginator {
    justify-content: left;
    overflow: hidden;
    width: 100%;
}

.image-gallery__paginator-list {
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    max-height: 80px;
    position: relative;
    transition: all .1s ease;
}

.image-gallery__paginator-item {
    cursor: pointer;
    filter: brightness(60%);
    flex: 0 1 auto;
    list-style-type: none;
    margin: 0;
    max-height: 80px;
    transition: all .1s ease;
}

.image-gallery__paginator-item.active {
    filter: brightness(110%);
}

.image-gallery__paginator-item :hover {
    filter: brightness(110%);
}

.image-gallery__paginator-item img {
    max-height: 80px;
    min-height: 80px;
    width: auto;
}

.thumbList-enter-active,
.thumbList-leave-active {
    transition: all .1s ease;
}

.thumbList-enter {
    opacity: 0;
}

.thumbList-enter-to {
    opacity: 1;
}

.thumbList-leave {
    opacity: 1;
}

.thumbList-leave-to {
    opacity: 0;
}

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.slides {
    flex-flow: row;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.slides > * {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-flow: row nowrap;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
}

.item {
    color: #ddd;
    flex-grow: 4;
    font-size: 36pt;
    height: 100%;
    position: absolute;
}

.item > div {
    transition: all .8s ease;
}

.out-left-enter-active,
.out-left-leave-active,
.out-right-enter-active,
.out-right-leave-active,
.fade-enter-active,
.fade-leave-active {
    transition: all .7s ease;
}

.out-left-leave {
    opacity: 1;
    transform: translateX(0);
}

.out-left-leave-to,
.out-right-enter {
    opacity: 1;
    transform: translateX(-100%);
}

.out-left-enter,
.out-right-leave-to {
    opacity: 1;
    transform: translateX(100%);
}

.fade-enter,
.fade-leave-to {
    opacity: 1;
}

@media screen and (min-width: 800px) {
    .image-gallery__body {
        height: calc((133vw - 5rem) / 3);
    }
}

@media screen and (min-width: 1200px) {
    .image-gallery__body {
        height: 490px;
    }
}
