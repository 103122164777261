.p-bgimage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
}
