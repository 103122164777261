.p-internal-link {
    font-size: 1em; /* 14px@320, 16pc@1200 */
    font-weight: 400;
    line-height: 1.6;
    margin: .5em 0 0;
    padding-left: 1.3em;
    position: relative;
    text-align: left;
}

.p-internal-link,
.p-internal-link:visited {
    text-decoration: none;
}

.p-internal-link__icon {
    display: inline-block;
    fill: currentcolor;
    height: .9em;
    left: 0;
    position: absolute;
    top: .35em;
    width: .9em;
}
