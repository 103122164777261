.gradient-text {
    background: var(--gradient-red-to-blue);
}

@media screen and (max-width: 799px) {
    .gradient-text {
        &--hero {
            background: 0;
            hyphens: auto;
            margin-right: .5rem;
        }
    }
}

@media screen and (min-width: 800px) {
    .gradient-text {
        &--hero {
            display: table;
            margin: .4rem 1rem .4rem 0;
            padding: .8rem .8rem .4rem;
            white-space: nowrap;
        }
    }
}
