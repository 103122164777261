.icon-list-badge__icon-list-wrapper {
    padding-bottom: 2.5em;
}

@media screen and (min-width: 576px) {
    .icon-list-badge__body {
        display: flex;
        gap: 30px;
    }

    .icon-list-badge__icon-list-wrapper {
        flex: 1.5;
    }

    .icon-list-badge__badge-wrapper {
        flex: 1;
        text-align: center;
    }
}
