.card-list__in {
    display: grid;
}

@media screen and (min-width: 800px) {
    .card-list__in {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .card-list__in {
        gap: 15px;
    }
}

@media screen and (min-width: 992px) {
    .card-list__in {
        gap: 30px;
    }
}
