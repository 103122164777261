.table {
    &__headline {
        color: var(--peppermint-700);
        margin-bottom: 25px;
    }
}

.table-wrap--auto {
    overflow-x: auto;
}

.table__row th {
    border-bottom-color: var(--white);
    border-right-color: var(--white);
    font-weight: 700;
}

.table__additional-info {
    font-size: .875em; /* 12px @320 */
    margin: .75em 0;
}

.table--has-thead .table__row:nth-child(odd) {
    background-color: inherit;
}

@media screen and (max-width: 767px) {
    .table--main th {
        display: none;
    }

    .table--main td {
        display: block;

        &::before {
            content: attr(data-hl);
            display: block;
            font-size: 12px;
            margin-bottom: 5px;
        }
    }

    .table--main tr:nth-child(even) {
        background-color: transparent;
    }

    .table--main tr:nth-child(odd) {
        background-color: var(--gray-100);
    }
}

