/**
 * member-form
 */

/**
 * Step 5: Validation
 */

/* Fieldsets with Link back to single steps for changing data */

.member-form__validation-fieldset-wrapper {
    position: relative;
}

.member-form__validation-fieldset-wrapper fieldset {
    margin: 0 0 2.5em;
}

.member-form__validation-fieldset-wrapper .form__fieldset--small-margin {
    margin-bottom: 1.35em;
}

@media screen and (min-width: 41.25em) {
    .member-form__change-data-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        width: 8.5rem;
    }

    .member-form__change-data-link {
        margin-top: .6em;
    }

    .member-form__change-data-wrapper .form__column {
        display: block;
        text-align: right;
    }

    .member-form__validation-fieldset-wrapper legend:first-child {
        padding-right: 8.5rem;
    }

    .member-form__validation-fieldset-wrapper fieldset {
        margin: 0 0 2.78em;
    }
}
