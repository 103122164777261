.progress__headline {
    @mixin font-hanken;
    font-size: 16px;
}

.progress__total {
    background: var(--gray-200);
    border-radius: 2px;
    height: 12px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.progress__percentage {
    animation: progress 2s;
    background: var(--peppermint-700);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

@keyframes progress {
    0% {
        width: 0;
    }
}
