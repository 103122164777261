.target-group__headline {
    color: var(--peppermint-900);
    margin-bottom: 25px;
    text-align: center;
}

.target-group__list {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 399px) {
    .target-group__item {
        width: 100%;
    }

    .target-group__item-link {
        width: 100%;
    }
}

@media screen and (min-width: 720px) {
    .target-group__list {
        margin: 0 auto;
        width: 100%;
    }
}

@media screen and (max-width: 799px) {
    .target-group__picture {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .target-group .image__img {
        border: 1px solid var(--gray-300);
        border-radius: var(--module-border-radius);
    }

    .target-group {
        font-size: .875rem; /* body font-size */
    }

    .target-group .target-group__body {
        padding: 2.5em var(--module-padding-left-right);
    }

    .target-group__item {
        max-width: 33.33%;
        position: relative;
        width: calc(33.33% - 10px);
    }

    .target-group__item-link {
        bottom: .625em;
        left: 0;
        margin: auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-overflow: ellipsis;
        width: 90%;
    }
}

@media screen and (max-width: 991px) {
    .target-group__list {
        gap: 15px;
        justify-content: center;
    }
}

@media screen and (min-width: 992px) {
    .target-group__list {
        gap: 30px;
        max-width: 100%;
    }

    .target-group__item {
        max-width: calc(100% / 6 - 25px);
        width: calc(100% / 6 - 25px);
    }
}
