.event-details__body.event-details__body {
    /* needed higher specificity than wrapper ('.page page__module-wrapper-inner') */
    padding-top: 0;
}

.event-details__item-wrapper {
    display: flex;
}

.event-details__item {
    align-items: center;
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: center;
}

.event-details__headline {
    color: var(--peppermint-700);
    margin-bottom: 0;
}

.event-details__icon {
    fill: var(--peppermint-700);
    height: 35px;
    width: 35px;
}

.event-details__text {
    margin: 0;
    max-width: none;
}

.event-details__text-line {
    display: block;
}

.event-details__external-link-hint {
    display: block;
    margin-top: 15px;
    text-align: left;
}

@media screen and (max-width: 499px) {
    .event-details__item-wrapper {
        flex-direction: column;
        gap: 15px;
    }

    .event-details__item {
        padding: 15px;
    }
}

@media screen and (min-width: 500px) {
    .event-details__item-wrapper {
        gap: 30px;
    }

    .event-details__item {
        flex: 1;
        max-width: 50%;
        padding: 30px;
    }

    .event-details__hint {
        margin: .625em;
    }
}
