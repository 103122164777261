.p-hint {
    color: var(--gray-500);
    font-size: 12px;
    margin: 0;
}

.p-hint--spaced {
    margin-block: 25px;
}

.p-hint--asterik {
    font-weight: normal;
    padding-left: 1em;
}

.p-hint__asterik {
    display: inline-block;
    margin-left: -1em;
    width: 1em;
}

