.btn {
    border: 0;
    border-radius: 10px;
    font-size: 15px;
    font-weight: normal;
    position: relative;
    text-align: center;
}

.btn--medium {
    padding: 12px 20px;
}

.btn--large {
    padding: 16px 32px;
}

.btn--w100 {
    width: 100%;
}

.btn:link,
.btn:visited,
.btn:hover,
.btn:active {
    text-decoration: none;
}

.btn--main,
.btn--main:visited {
    background: var(--peppermint-700);
    border: 1px solid var(--peppermint-700);
    color: var(--white);
    transition: background-color .3s ease, border-color .3s ease;
}

.btn--main:hover {
    background: var(--peppermint-500);
    border-color: var(--peppermint-500);
    color: var(--white);
}

.btn--main:focus {
    background: var(--peppermint-400);
    border-color: var(--peppermint-400);
    color: var(--white);
    outline: 0;
}

.btn--aside,
.btn--aside:visited {
    background: var(--white);
    border: 1px solid var(--gray-300);
    color: var(--peppermint-700);
    transition: background-color .3s ease, border-color .3s ease, outline .3s ease;
}

.btn--aside:hover {
    border-color: var(--gray-400);
    color: var(--peppermint-700);
}

.btn--aside:focus {
    background-color: var(--gray-100);
    border-color: var(--gray-300);
    color: var(--peppermint-700);
    outline: 2px solid var(--gray-300);
}

.btn--aside:active {
    background-color: var(--peppermint-50);
    border-color: var(--gray-400);
    color: var(--peppermint-700);
    outline: 0;
}
