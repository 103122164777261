.article-head__headline--content {
    margin-bottom: 25px;
}

.article-head__subheadline {
    color: var(--peppermint-900);
    display: block;
    line-height: 1.2;
    text-transform: none;
}

.article-head__intro {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0;
}

.article-head__intro strong {
    font-weight: normal;
}

.article-head__hint {
    font-size: 0.7rem;
    font-style: italic;
    margin-top: 0.8rem;
}

.article-head__date,
.article-head__category {
    display: block;
    line-height: 1.3;
}

.article-head__date {
    font-size: 1.125em;
}

.article-head__category {
    color: var(--peppermint-700);
    font-size: 1.25em;
}

.article-head--landing .article-head__headline,
.article-head--landing .article-head__subheadline,
.article-head--landing .article-head__intro {
    color: var(--peppermint-700);
}

.article-head--landing .article-head__headline {
    margin-block-end: 12px;
}

.article-head--landing .article-head__subheadline {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1.1;
    margin-block-start: 0;
}

.article-head--landing .article-head__intro {
    font-size: 16px;
    line-height: 1.2;
}

@media screen and (min-width: 800px) {
    .article-head--landing .article-head__subheadline {
        font-size: 27px;
        margin-block-start: 28px;
    }
}
