/*
 * opening-hours
 */
.opening-hours__table {
    margin-bottom: 0;
}

.opening-hours__cell--short {
    width: 33.3333%;
}

.opening-hours__cell--long {
    width: 66.6666%;
}

.opening-hours__additional-info {
    font-size: .875em; /* 12px @320 */
    margin-top: .75em;
}
