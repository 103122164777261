.teaser-campaign {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.teaser-campaign__text {
    background: var(--white);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    padding: 15px;
}

.teaser-campaign__headline {
    @mixin font-hanken;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 0;
}

.teaser-campaign__link-wrapper {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 0;
}

.teaser-campaign__link {
    align-items: center;
    display: flex;
    gap: 5px;
    margin-top: auto;
    transition: color .3s ease, fill .3s ease;
}

.teaser-campaign__link,
.teaser-campaign__link:visited {
    color: var(--peppermint-700);
    fill: var(--peppermint-700);
    text-decoration: none;
}

.teaser-campaign__link:hover,
.teaser-campaign__link:focus {
    color: var(--peppermint-500);
    fill: var(--peppermint-500);
    text-decoration: none;
}

.teaser-campaign__link-icon {
    height: 14px;
    width: 14px;
}

.js.no-touchevents .teaser-campaign:hover .image__img {
    filter: saturate(1.3);
}

.teaser-campaign--carousel-item {
    background: currentColor;
    flex-direction: column;
    height: 100%;
}

.teaser-campaign--carousel-item .teaser-campaign__picture {
    flex: 0 0 auto; /* important for IE11 */
}

.teaser-campaign--carousel-item .teaser-campaign__text {
    min-height: 200px;
}
