.map {
    &.is-active {
        min-height: 500px;
    }

    &-text {
        &.is-hidden {
            display: none;
        }
    }

    .gm-style h3 {
        @mixin font-hanken;
        color: var(--peppermint-700);
        font-size: 20px;
    }

    .gm-style p {
        font-size: 14px;
    }

    .gm-style-iw-d a {
        align-items: center;
        display: flex;
        gap: 5px;
        transition: color .3s ease;
    }

    .gm-style-iw-d a::after {
        background: url("../img/icons/arrow-right.svg") no-repeat center center;
        background-size: contain;
        content: "";
        display: block;
        height: 14px;
        width: 14px;
    }

    .gm-style-iw-d a,
    .gm-style-iw-d a:visited {
        color: var(--peppermint-700);
        text-decoration: none;
    }

    .gm-style-iw-d a:hover,
    .gm-style-iw-d a:focus {
        color: var(--peppermint-500);
        text-decoration: none;
    }
}
