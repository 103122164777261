
@media screen {
    .print {
        margin-top: 10px;
    }

    .print__content-hidden {
        display: none;
    }
}

@media print {
    .print__list {
        color: var(--grey-dark);
        display: flex;
        flex-wrap: wrap;
        line-height: 1;
        padding-left: 40px;
        visibility: visible;
    }

    .print__list-item {
        margin: 0;
        width: 50%;
    }

    dt.print__list-item {
        flex-basis: 35%;
        padding: 6px 0;
    }

    dd.print__list-item {
        font-weight: 600;
        padding: 6px 0;
    }

    .print__list-item:nth-child(16),
    .print__list-item:nth-child(26) {
        margin-bottom: 20px;
    }

    .hidden-in-print {
        background: #fff;
        height: 100vh;
        overflow: hidden;
    }

    .print {
        color: var(--grey-dark);
        display: block;
        height: 100vh;
        visibility: visible;
    }

    .hidden-in-print main,
    .hidden-in-print footer {
        display: none;
        height: 0;
        visibility: hidden;
    }

    .hidden-in-print .page__header {
        visibility: visible;
    }
}
