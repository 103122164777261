.search-result {
    &__badge {
        padding: 8px 15px;
        right: 0;
        top: 50%;
        transform: translate(40%, -50%) rotate(90deg);
        z-index: 1;

        &::before {
            border: 5px solid var(--peppermint-700);
            border-left-color: transparent;
            border-top-color: transparent;
            box-sizing: content-box;
            content: "";
            display: block;
            left: -10px;
            top: 0;
            z-index: -1;
        }

        &--healthcare {
            background: var(--peppermint-700);
            color: var(--white);
            font-size: 14px;
        }
    }

    &__event-data-wrapper {
        margin: 1em 0 .3em;
    }

    &__headline {
        @mixin font-hanken;
        color: var(--peppermint-700);
        margin-bottom: 25px;

        .search-result--download & {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__icon {
        display: inline-block;
        fill: var(--gray-400);
        height: 14px;
        margin-right: 8px;
        width: 14px;
    }

    &__icon-detail {
        display: block;
        margin-top: .3em;
        padding-left: 1.5em;
        position: relative;

        &:first-child {
            margin-top: 0;
        }
    }

    &__link {
        align-items: center;
        display: flex;
        gap: 5px;
        transition: color .3s ease, fill .3s ease;
    }

    &__link,
    &__link:visited {
        color: var(--peppermint-700);
        fill: var(--peppermint-700);
        text-decoration: none;
    }

    &__link:hover,
    &__link:focus {
        color: var(--peppermint-500);
        fill: var(--peppermint-500);
        outline: 0;
        text-decoration: none;
    }

    &__link-icon {
        height: 14px;
        width: 14px;
    }

    &__wrapper {
        background: var(--white);
        border: 1px solid var(--gray-300);
        border-radius: var(--module-border-radius);
        position: relative;
    }

    &__download {
        align-items: center;
        display: flex;
    }
}

/*
 * Required because position rules got be lost during minification
 * and the badge is not displayed correctly anymore, maybe postcss or cssnano bug
 */
.search-result__badge,
.search-result__badge::before {
    position: absolute;
}

@media screen and (max-width: 767px) {
    .search-result__wrapper {
        padding: 15px 45px 15px 15px;
    }
}

@media screen and (min-width: 768px) {
    .search-result__wrapper {
        padding: 30px 45px 30px 30px;
    }
}
