.sv-lexicon__initials-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 50px;
}

.sv-lexicon__initial {
    font-size: 1.75em;
    line-height: 1.8em;
    padding: 0;
    width: 1.8em;
}

.sv-lexicon__list-item {
    display: block;
    margin-bottom: 30px;
}

.sv-lexicon__list-item-title {
    margin: 0;
}

@media screen and (min-width: 500px) {
    .sv-lexicon__list-wrapper {
        column-count: 2;
    }
}
