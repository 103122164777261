.social-sharing__body.social-sharing__body {
    padding-block: calc(var(--module-padding-top-bottom) / 1.5);
}

.social-sharing__ctr {
    .page--bridge &,
    .page--content & {
        align-items: center;
        border-bottom: 1px dotted var(--peppermint-500);
        border-top: 1px dotted var(--peppermint-500);
        display: flex;
    }
}

.social-sharing__list-wrapper {
    padding: 1em .5em;
}

.social-sharing__headline {
    color: var(--peppermint-700);
    font-size: 20px;
    white-space: nowrap;

    .page--landing & {
        display: none;
    }
}

.social-sharing__list {
    display: table; /* clearfix */
}

.social-sharing__item {
    display: block;
    float: left;
    padding: 0 .5em;
}

.social-sharing__link {
    display: block;
    text-decoration: none;
    white-space: nowrap;

    &:link {
        text-align: center;
    }

    .page--bridge &,
    .page--content & {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .page--bridge &,
    .page--bridge &:link,
    .page--bridge &:visited,
    .page--content &,
    .page--content &:link,
    .page--content &:visited {
        background: var(--peppermint-700);
        border-radius: 50%;
        height: 40px;
        padding: 0;
        position: relative;
        transition: background-color .3s ease;
        width: 40px;
    }

    .page--bridge &:focus,
    .page--content &:focus {
        background: var(--peppermint-400);
    }

    .page--bridge &:hover,
    .page--content &:hover {
        background: var(--peppermint-500);
    }
}

.page--bridge .social-sharing__text,
.page--content .social-sharing__text,
.page--landing .social-sharing__text {
    @extend .u-visually-hidden;
    white-space: nowrap;
}

.social-sharing__icon {
    height: 2.5em;
    width: 2.5em;

    &--email {
        transform: scale(0.6);
    }

    &--linkedin {
        transform: scale(0.5);
    }

    .page--bridge &,
    .page--content & {
        fill: var(--white);
    }

    .page--bridge &--facebook,
    .page--content &--facebook {
        transform: scale(1.6) translateY(3px);
    }

    .page--bridge &--linkedin,
    .page--content &--linkedin {
        transform: scale(0.5);
    }

    .page--bridge &--whatsapp,
    .page--content &--whatsapp {
        transform: translateY(-1px);
    }
}

.social-sharing--inverted .social-sharing__list-wrapper {
    padding-left: 0;
}

.social-sharing--inverted .social-sharing__item:first-child {
    margin-left: 0;
    padding-left: 0;
}

.social-sharing--inverted .social-sharing__link {
    background-color: var(--transparent);
    border: 1px solid var(--peppermint-700);
    border-radius: var(--module-border-radius);
    color: var(--peppermint-700);
    fill: var(--peppermint-700);
    white-space: nowrap;
}

.social-sharing--inverted .social-sharing__link:focus,
.social-sharing--inverted .social-sharing__link:hover {
    background-color: var(--transparent-hover);
    border-color: var(--peppermint-700);
}

.social-sharing--centered .social-sharing__list {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.social-sharing--landing .social-sharing__body,
.social-sharing--landing .social-sharing__list-wrapper {
    padding: 0;
}

@media screen and (max-width: 420px) {
    .social-sharing__ctr {
        .page--content & {
            flex-direction: column;
        }
    }
}

@media screen and (min-width: 700px) {
    .social-sharing__list {
        display: flex;
    }

    .social-sharing__link:link,
    .social-sharing__link:hover,
    .social-sharing__link:focus,
    .social-sharing__link:active {
        font-size: .938em;
        padding: .35em 1em .35em 2.5em;
        position: relative;
    }

    .social-sharing__text,
    .page--landing .social-sharing__text {
        @extend .u-visually-not-hidden;

        .page--content & {
            @extend .u-visually-hidden;
        }
    }

    .page--landing .social-sharing__icon {
        left: 0;
        position: absolute;
        top: -.1em;
    }
}

@media screen and (min-width: 640px) {
    .social-sharing--inverted .social-sharing__text {
        width: auto;
    }
}

@media screen and (min-width: 768px) {
    .page--landing .social-sharing__item {
        flex: 0 1 110px;
    }
}
