.diagram__figure {
    margin: 0 auto;
    width: 85vmin;
}

.diagram__figure figcaption {
    padding: .5rem 0;
}

.diagram__caption-text {
    display: inline-block;
    font-size: .875em;
    margin-top: .75em;
}

.diagram__legend-list {
    padding-left: 0;
}

.diagram__legend-list-item {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: .2rem;
}

.diagram__legend-mark {
    border: 1px solid var(--scale);
    display: block;
    flex: 0 0 25px;
    height: 14px;
    margin-right: .5rem;
    width: 25px;
}

.diagram__legend-text {
    font-size: .7rem;
    line-height: 1.25;
}

@media screen and (min-width: 480px) {
    .diagram__figure {
        margin: auto;
        max-width: 62%;
        width: 70vmin;
    }
}

@media screen and (min-width: 800px) {
    .diagram__figure {
        margin: 0 auto;
        max-width: 70vmin;
        width: 62%;
    }
}
