/**
 * image
 */

.image__figure {
    margin: 0;
}

.image__caption-text {
    display: inline-block;
    font-size: .875em; /* 12px @320 */
    margin-top: .75em;
}

.image__img {
    display: block;
    height: auto;
    margin: 0;
    max-width: 100%;
}

.image__img--full-width {
    width: 100%;
}

.lazyload {
    opacity: 0;
}

.no-js .lazyload {
    display: none;
}

.no-cssanimations .lazyload {
    opacity: 1;
}

.lazyloaded {
    animation: fadeIn .1s ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; }

    to { opacity: 1; }
}
