.badges {
    text-align: center;
}

.badges--aside {
    padding-top: var(--module-padding-top-bottom);
    position: relative;

    &::before {
        background-image: linear-gradient(to right, var(--peppermint-900) 50%, transparent 50%);
        background-position: left top;
        background-repeat: repeat-x;
        background-size: 10px 3px;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        top: var(--module-padding-top-bottom);
        transform: translateX(-50%);
        width: 150px;
    }
}

.badges__headline {
    color: var(--peppermint-900);
    margin-bottom: 35px;
}

.badges__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.badges__item {
    max-width: calc(33.3333% - 20px);
}

.badges .image__img {
    margin: auto;
}

@media screen and (min-width: 780px) {
    .badges .image__img {
        width: 100%;
    }
}
