.teaser-highlight .teaser-highlight__wrapper {
    padding-top: 0;
}

.teaser-highlight__body {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    overflow: hidden;
}

.teaser-highlight__picture {
    height: calc(50vw - 2 * 1.45em - 2 * var(--module-padding-left-right));
    min-height: 10.71em;
    overflow: hidden;
    position: relative;
}

.teaser-highlight__picture img {
    margin-top: -25%;
}

.teaser-highlight__text-wrapper-inner {
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(50vw - 2 * 1.45em - 2 * var(--module-padding-left-right)); /* square shape together with image */
    padding: 1em;
}

.teaser-highlight__headline {
    color: var(--blue);
    font-size: 1.5rem; /* 24px@320px */
    margin-bottom: .5rem;
}

.teaser-highlight__text {
    display: none;
}

.js.no-touchevents .teaser-highlight .image__img:hover {
    filter: saturate(1.3);
}

.teaser-highlight__link {
    align-items: center;
    display: flex;
    gap: 5px;
    transition: color .3s ease, fill .3s ease;
}

.teaser-highlight__link,
.teaser-highlight__link:visited {
    color: var(--peppermint-700);
    fill: var(--peppermint-700);
    text-decoration: none;
}

.teaser-highlight__link:hover,
.teaser-highlight__link:focus {
    color: var(--peppermint-500);
    fill: var(--peppermint-500);
    text-decoration: none;
}

.teaser-highlight__link-icon {
    height: 14px;
    width: 14px;
}

@media screen and (min-width: 400px) {
    .teaser-highlight__headline {
        font-size: 1.15rem;
        margin-bottom: 0;
    }

    .teaser-highlight__body {
        display: flex;
    }

    .teaser-highlight__picture {
        flex: 1 0 33.33%;
        height: auto;
        max-width: 12.5em;
        min-height: 0;
        min-width: 9.375em;
    }

    .teaser-highlight__picture img {
        margin: 0;
    }

    .teaser-highlight__text-wrapper {
        flex: 2 1 66.66%;
        padding: 0;
        position: relative;
    }

    .teaser-highlight__text-wrapper-inner {
        height: 100%;
        min-height: 0;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    .teaser-highlight__headline {
        overflow: hidden;
    }
}

@media (min-width: 560px) {
    .teaser-highlight__headline {
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    .teaser-highlight__text-wrapper-inner {
        justify-content: flex-start;
    }

    .teaser-highlight__text {
        display: block;
        flex: 0 1 4rem;
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
    }

    .teaser-highlight__text::after {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        bottom: 0;
        content: "";
        display: block;
        height: 2rem;
        left: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
    }

    .teaser-highlight__link-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .teaser-highlight__text + .teaser-highlight__link-wrapper {
        justify-content: flex-end;
    }
}

@media screen and (min-width: 800px) and (max-width: 899px) {
    .page--content .teaser-highlight__headline {
        margin-bottom: 0;
    }

    .page--content .teaser-highlight__text {
        display: none;
    }
}

@media screen and (min-width: 900px) {
    .page--content .teaser-highlight__picture {
        flex: 1 0 13.75em;
        max-width: 13.75em;
    }

    .page--content .teaser-highlight__text-wrapper {
        flex: 1;
    }
}
