.upload__headline {
    color: var(--peppermint-700);

    &--main {
        margin-bottom: 25px;
    }
}

.upload__item {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    overflow: hidden;

    &:first-child {
        border: 1px solid var(--gray-300);
    }
}

.upload__preview-images .upload__item {
    padding: var(--module-padding-left-right);
}

.upload__picture-wrapper {
    width: 100%;
    height: 120px;

    .upload__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .image__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;

        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
}

.upload .image__img:hover {
    filter: invert(15%);
}

.upload__text-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.upload__item-text p {
    margin: .6em 0 .7em;
}

.upload__link,
.upload__link:visited {
    background: transparent;
    color: var(--gray-700);
    fill: var(--gray-700);
    transition: background-color .3s ease;
    width: 100%;
}

.upload__link:focus,
.upload__link:hover {
    background: var(--gray-100);
    color: var(--gray-900);
    fill: var(--gray-900);
}

.upload__name {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload__meta {
    white-space: nowrap;
}

.upload__date {
    display: block;
    font-size: 1em;
    margin-bottom: 0;
}

.upload__list {
    display: flex;
    flex-direction: column;
    gap: var(--module-padding-left-right);
}

@media screen and (min-width: 640px) {
    .upload__item {
        display: flex;
    }

    .upload__preview-images .upload__text-wrapper {
        flex: 3;
    }

    .upload__picture-wrapper {
        width: 150px;
        padding: 0 15px;

        .upload__picture {
            display: block;
        }

        .image__picture {
            width: 100%;
            height: 100%;
        }
    }

    .upload__link-details-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .upload__name {
        padding-right: 1em;
        width: 100%;
    }

    .upload__date {
        text-align: right;
        white-space: nowrap;
        width: 30%;
    }

    .upload__preview-images .upload__date {
        text-align: left;
    }

    .upload__preview-images .upload__item {
        padding-inline: 0;
    }
}
