.pagination {
    margin-top: 15px;
}

.pagination__list {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.pagination__entry {
    align-items: center;
    display: flex;
    fill: var(--peppermint-700);
    font-size: 1.125em;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.pagination__entry--current {
    color: var(--gray-500);
}

.pagination__item--more-pages ~ .pagination__item--more-pages {
    /* hide second item with '...' text */
    display: none;
}

.pagination__entry--control,
.pagination__entry--control:visited,
a.pagination__entry--control:focus,
a.pagination__entry--control:hover {
    fill: var(--peppermint-700);
}

a.pagination__entry--control:focus,
a.pagination__entry--control:hover {
    fill: var(--peppermint-500);
}

.pagination__icon {
    height: 14px;
    width: 14px;
}

.pagination__icon--prev {
    transform: rotate(180deg);
}

@media screen and (min-width: 400px) {
    .pagination__item--more-pages ~ .pagination__item--more-pages {
        display: block;
    }
}
