.search__header {
    text-align: center;
}

.search__noresult {
    margin-top: 2em;
}

.search__wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
