/*
 * service-center
 */

.service-center__notification.service-center__notification {
    /* higher speficity by double class because of nested page__module-wrapper-inner class */
    padding-bottom: 0;
    padding-top: .5em;
}

.service-center__notification > .message {
    margin: 0;
}
