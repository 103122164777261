.news-list__headline {
    margin-bottom: 0;
}

.news-list__headline::after,
.news-list__headline::before {
    content: "++";
}

.news-list__wrapper {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.news-list__wrapper li {
    display: flex;
}

.news-list__link {
    margin: 0;
}

.news-list__link .p-internal-link:hover,
.news-list__link .p-internal-link:focus {
    text-decoration: none;
}

.news-list--next .teaser-news__title:link,
.news-list--next .teaser-news__title:visited,
.news-list--next .teaser-news__title:active {
    text-decoration: none;
    transition: color .3s ease;
}

.news-list--next .teaser-news__title:hover,
.news-list--next .teaser-news__title:focus {
    color: var(--peppermint-500);
    text-decoration: none;
}

.news-list--next .news-list__link .p-internal-link {
    color: inherit;
}

.news-list--next .news-list__link .p-internal-link__icon,
.news-list--next .news-list__link .p-internal-link__icon:link,
.news-list--next .news-list__link .p-internal-link__icon:visited,
.news-list--next .news-list__link .p-internal-link__icon:active {
    fill: var(--peppermint-900);
}

@media screen and (max-width: 799px) {
    .news-list__wrapper li {
        width: 100%;
    }
}

@media screen and (min-width: 800px) {
    .news-list__wrapper li {
        width: 50%;
    }
}
