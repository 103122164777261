.text ul,
.text ol {
    list-style: none;
    margin: 0 0 1.8em;
    max-width: 50em;
    overflow: hidden;
    padding: 0;
}

.text ul:last-child,
.text ol:last-child {
    margin-bottom: 0;
}

.text ul > li {
    background-image: url("../img/icons/arrow-small-right.svg");
    background-position: 0 .49em;
    background-repeat: no-repeat;
    background-size: .5em;
    padding: 0 0 0 1em;
}

.text ol {
    counter-reset: item;
}

.text ol > li {
    padding-left: 2.2em;
    position: relative;
}

.text ol > li::before {
    color: var(--peppermint-700);
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: 700;
    left: 0;
    position: absolute;
    top: 0;
}

.text p:last-child {
    margin-bottom: 0;
}

.text aside {
    color: var(--grey-medium-dark);
    font-size: 1.5em;
    line-height: 1.5;
    margin: 0 0 1.2em;
    padding: 1em 2em;
    text-align: center;
}

.text aside a {
    font-size: .666em;
}

.text__body {
    overflow: hidden;
}

.text__picture {
    margin-bottom: 1em;
    margin-top: .5em;
}

@media screen and (min-width: 480px) {
    body {
        hyphens: none;
    }

    .text__picture {
        float: left;
        margin-right: 1em;
        width: 50%;
    }

    .text__picture--right {
        float: right;
        margin-left: 1em;
        margin-right: 0;
    }
}

@media screen and (min-width: 800px) {
    .text__picture {
        width: 33%;
    }
}

.text--centered .text__headline {
    color: var(--peppermint-900);
    text-align: center;
}

.text--centered .text__body {
    padding: 1.45em;
}
