.related-content {
    .link-stack__link {
        padding: 0.5em 1em 0.5em 1.5em;
    }

    .link-stack__icon {
        margin: 0 0 0 8px;
    }

    &__link {
        transition: background-color .3s ease;
    }

    &__link,
    &__link:visited {
        color: var(--gray-700);
        fill: var(--gray-700);
    }

    &__link:focus,
    &__link:hover {
        background-color: var(--gray-100);
        color: var(--gray-700);
        fill: var(--gray-700);
    }
}

.related-content__headline {
    color: var(--peppermint-700);
    margin-bottom: 25px;
}

.related-content__icon {
    width: .5em;
}

.related-content__item + .related-content__item {
    border-top: 1px solid var(--gray-300);
}
