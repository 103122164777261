/* Firefox: don't use fill property, Chrome don't use color */
a.social-sharing__link {
    color: var(--white);
    fill: var(--white);
}

/* fix IE 10 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .js .service-label__item {
        overflow: inherit;
    }

    .js .service-label__front,
    .js .service-label__back {
        backface-visibility: visible;
    }
}

/* fix IE 11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .teaser-carousel__item {
        display: block;
    }
}
