.teaser-event {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    margin: 0 0 var(--module-padding-left-right);
    padding: 1.3em 1.45em;
}

.teaser-event__headline {
    @mixin font-hanken;
    color: var(--peppermint-700);
    margin-bottom: 25px;
}

.teaser-event__meta-wrapper {
    color: var(--peppermint-700);
    fill: var(--peppermint-400);
    font-size: 1em;
    margin: 0 0 .5em;
    padding-left: 1.7em;
    position: relative;
}

.teaser-event__icon {
    height: 1.2em;
    right: auto;
    width: 1.2em;
}

.teaser-event__link-wrapper {
    margin: 1.1em 0 0;
}

.teaser-event__link {
    align-items: center;
    display: flex;
    gap: 5px;
    margin-top: auto;
    transition: color .3s ease, fill .3s ease;
}

.teaser-event__link,
.teaser-event__link:visited {
    color: var(--peppermint-700);
    fill: var(--peppermint-700);
    text-decoration: none;
}

.teaser-event__link:hover,
.teaser-event__link:focus {
    color: var(--peppermint-500);
    fill: var(--peppermint-500);
    text-decoration: none;
}

.teaser-event__link-icon {
    height: 14px;
    width: 14px;
}

@media screen and (min-width: 800px) {
    .teaser-event {
        margin: 0 0 calc(1.5 * var(--module-padding-left-right));
        padding: 1em 1.45em;
    }

    .teaser-event__headline {
        font-size: 27px;
    }
}
