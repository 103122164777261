.contact-box {
    height: 0;
    inset: 2rem 0 auto;
    position: absolute;
    width: auto;
    z-index: var(--z-index-0);
}

.contact-box__wrapper {
    height: 100%;
    margin: auto;
    max-width: var(--module-max-width);
    padding: 0 calc(var(--module-padding-left-right) * 2.5);
    position: relative;
}

.contact-box__body {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    bottom: auto;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    min-height: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    right: var(--module-padding-left-right);
    top: 0;
    width: 30.3333%;
}

.contact-box__wrapper-button {
    background: var(--gray-50);
    border-top: 1px solid var(--gray-300);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--module-padding-left-right);
}

.contact-box__wrapper-content {
    padding: var(--module-padding-left-right);
}

.contact-box__headline {
    color: var(--gray-900);
    font-size: 27px;
}

.contact-box__link {
    align-items: center;
    display: flex;
    gap: 8px;
}

.contact-box__link,
.contact-box__link:visited {
    color: var(--peppermint-700);
    text-decoration: none;
    transition: color .3s ease;
}

.contact-box__link:focus,
.contact-box__link:hover {
    color: var(--peppermint-500);
}

.contact-box__icon {
    fill: var(--peppermint-700);
    height: 16px;
    width: 16px;
}

.contact-box--sticky-top {
    position: fixed;
    top: 1rem;
    transition: top .5s cubic-bezier(.68, -.55, .265, 1.55);
}

.contact-box__list {
    display: flex;
    flex-direction: column;
    gap: var(--module-padding-left-right);
}

@media screen and (max-width: 991px) {
    .contact-box {
        display: none;
    }
}
