/**
 * payment-calculator
 */

.payment-calculator__gross-income-row {
    align-items: center;
}

.payment-calculator__gross-income-column {
    margin-bottom: 0;
}

.payment-calculator__cell--no-right-border {
    border-right: none;
}

.payment-calculator__cell--text-right {
    text-align: right;
}

.payment-calculator__result-cell {
    background-color: var(--grey-medium);
    border-bottom-color: var(--white);
    border-right-color: var(--white);
    font-weight: 700;
}

.payment-calculator__additional-info {
    font-size: .875em; /* 12px @320 */
    margin: .75em 0;
}
