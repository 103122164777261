.small-print {
    &__wrapper {
        margin: auto;
        max-width: var(--module-max-width);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__link,
    &__link:visited {
        color: var(--white);
        text-decoration: none;
        transition: color .3s ease;
    }

    &__link:focus,
    &__link:hover {
        color: var(--peppermint-300);
        outline: 0;
    }

    &__text {
        color: var(--white);
        text-align: right;
    }
}

@media screen and (min-width: 800px) {
    .small-print__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-inline: var(--module-padding-left-right);
    }
}

@media screen and (max-width: 991px) {
    .small-print__list {
        column-gap: 20px;
        row-gap: 15px;
    }

    .small-print__text {
        margin-top: 50px;
    }
}

@media screen and (min-width: 992px) {
    .small-print__list {
        gap: 25px;
    }
}
