.service-label__item {
    margin-bottom: .6em;
    perspective: 50em;
    position: relative;
}

.service-label__icon-wrapper {
    bottom: 0;
    display: inline-block;
    height: 2.3em;
    left: .7em;
    margin: auto;
    position: absolute;
    top: 0;
    width: 2.3em;
}

.service-label__icon {
    height: 1.2em;
    width: .95em;
}

.service-label__headline.service-label__headline {
    font-family: var(--tahoma);
    font-size: 1em;
    font-weight: normal;
    margin: 0;
}

.service-label__text {
    margin: 0;
    padding-left: 3.6em;
    transform: translateZ(0);
}

.js .service-label__item {
    cursor: pointer;
    outline: none;
}

.js .service-label__card {
    position: relative;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    transition: transform .6s cubic-bezier(0, 1, 1, 1), background-color .3s, height .3s;
}

.js .service-label__card:hover,
.js .service-label__card:focus {
    will-change: transform;
}

.js .service-label__front,
.js .service-label__back {
    backface-visibility: hidden;
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    left: 0;
    padding: 1em .8em;
    position: absolute;
    top: 0;
    transform-style: preserve-3d;
    width: 100%;
}

.js .service-label__front:hover,
.js .service-label__item:focus .service-label__front,
.js .service-label__back:hover,
.js .service-label__item:focus .service-label__back {
    background-color: var(--gray-100);
}

.js .service-label__front {
    opacity: 1;
    transform: rotateX(0deg) translateZ(1px);
    transition: opacity .3s, background-color .3s;
    transition-delay: .15s;
}

.js .service-label__back {
    opacity: 0;
    transform: rotateX(180deg) translateZ(1px);
    transition: opacity .3s, background-color .3s;
}

/*
 * State flipped (flip class set by js)
 */
.service-label__item--flipped .service-label__card {
    transform: rotateX(-180deg);
}

.service-label__item--flipped .service-label__icon-wrapper {
    transition: fill .3s linear, background-color .3s linear;
}

.service-label__item--flipped .service-label__front {
    opacity: 0;
    transition: opacity .3s;
}

.service-label__item--flipped .service-label__back {
    opacity: 1;
    transition: opacity .3s;
    transition-delay: .15s;
}

.no-js .service-label__back {
    @extend .u-visually-not-hidden;
    padding-top: .5rem;
}

.no-js .service-label__icon-wrapper--back {
    display: none;
}

.no-js .service-label__item {
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    padding: 10px;
}

.no-svg .service-label__icon-wrapper {
    display: none;
}

.no-svg .service-label__item {
    padding: 1em;
}
