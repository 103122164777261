.teaser-chat {
    color: var(--white);
    position: relative;
    z-index: var(--z-index-0);
}

.teaser-chat__icon {
    fill: var(--white);
}

.teaser-chat__wrapper {
    position: relative;
}

.teaser-chat__background::after {
    background-image: linear-gradient(-43deg, rgba(0, 84, 145, .85), rgba(208, 16, 58, .85));
    content: "";
    display: block;
    inset: 0;
    position: absolute;
}

.teaser-chat__body {
    padding: 1.45em;
    position: relative;
    z-index: var(--z-index-0);
}

.teaser-chat__headline {
    color: var(--white);
}

.teaser-chat__kicker {
    display: block;
    font-size: .6em;
}

.teaser-chat__icon {
    display: inline-block;
    height: .9em;
    margin-right: .25em;
    width: .9em;
}

.teaser-chat__button {
    margin-right: 1em;
}

.teaser-chat__link {
    margin-top: 0;
}

.teaser-chat__button + .teaser-chat__link {
    margin-top: 1.5em;
}

.teaser-chat__link:link,
.teaser-chat__link:visited,
.teaser-chat__link:hover,
.teaser-chat__link:focus,
.teaser-chat__link:active {
    color: var(--white);
}

.teaser-chat__link:hover {
    text-decoration: underline;
}
