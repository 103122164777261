.social-pages {
    &__body {
        display: flex;
        gap: 30px;
    }

    &__headline {
        @mixin font-hanken;
        color: var(--white);
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        text-align: right;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        background: var(--white);
        border-radius: 50%;
        display: block;
        transition: background-color 0.3s ease;

        &:link,
        &:visited,
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:focus,
        &:hover {
            background: var(--peppermint-300);
            outline: 0;
        }
    }

    &__icon {
        fill: var(--peppermint-700);
        height: 32px;
        width: 32px;

        &--facebook {
            transform: translateY(5px) scale(1.7);
        }

        &--instagram {
            transform: scale(0.6);
        }

        &--linkedin {
            transform: scale(0.5);
        }

        &--youtube {
            transform: scale(0.7);
        }

        &--tiktok {
            transform: scale(0.6);
        }
    }
}

@media screen and (max-width: 529px) {
    .social-pages__headline {
        max-width: 10ch;
    }
}

@media screen and (min-width: 800px) {
    .social-pages > .page__module-wrapper {
        padding-inline: var(--module-padding-left-right);
    }
}

@media screen and (max-width: 991px) {
    .social-pages__list {
        gap: 20px;
    }
}

@media screen and (min-width: 992px) {
    .social-pages__list {
        gap: 12px;
    }
}
