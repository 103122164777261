.video__figure {
    margin: 0;
}

.video__wrapper {
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

.video__iframe {
    height: 100%;
    width: 100%;
}

.video__caption {
    font-size: .875em; /* 12px @320 */
    margin-top: var(--module-padding-left-right);
}

.video__headline {
    color: var(--peppermint-700);
    margin-bottom: 25px;
}

.video--centered .video__headline {
    text-align: center;
}
