.facts__headline {
    color: var(--peppermint-700);
    margin-bottom: 25px;
    text-align: center;
}

.facts__content {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    line-height: normal;
    padding: 1.25em; /* 20px */
    position: relative;
    transition: border-color .3s ease;
}

.facts__content:has(.facts__link):focus,
.facts__content:has(.facts__link):hover {
    border-color: var(--peppermint-700);
}

.facts__icon-wrapper {
    padding-right: 1.25em; /* 20px */
}

.facts__icon {
    height: 65px;
    width: 65px;
}

.facts__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.facts__link {
    align-items: center;
    display: flex;
    gap: 5px;
    transition: color .3s ease, fill .3s ease;
}

.facts__link,
.facts__link:visited {
    color: var(--peppermint-700);
    fill: var(--peppermint-700);
    text-decoration: none;
}

.facts__link:hover,
.facts__link:focus {
    color: var(--peppermint-500);
    fill: var(--peppermint-500);
    outline: 0;
    text-decoration: none;
}

.facts__link-icon {
    height: 14px;
    width: 14px;
}

.facts__text {
    font-size: 1.375em; /* ~9px@320, 22px@1200 */
    margin: 0;
}

.facts__list {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 799px) {
    .facts__list {
        flex-direction: column;
        gap: 15px;
    }

    .facts__content {
        display: flex;
    }

    .facts__text-wrapper {
        gap: 15px;
    }
}

@media screen and (min-width: 800px) {
    .facts__list {
        gap: 30px;
    }

    .facts__content {
        height: 100%;
        padding-bottom: 2em;
        padding-top: 2.5em; /* 50px */
    }

    .facts__item {
        max-width: calc(100% / 3);
        width: calc(100% / 3);
    }

    .facts__icon-wrapper {
        padding: 0;
    }

    .facts__icon {
        height: 5.5em; /* 88px */
        left: 0;
        margin: 0 auto;
        right: 0;
        top: 1.8em;
        width: 7em; /* 112px */
    }

    .facts__text {
        padding-top: 1.3em; /* 35px */
        text-align: center;
    }

    .facts__text-wrapper {
        align-items: center;
        gap: 30px;
    }
}
