.icon-list__headline {
    margin-bottom: 25px;
}

.no-svg .icon-list__list {
    list-style-type: disc;
    padding-left: 3.6em;
}

.icon-list__item {
    padding-left: 2em;
    position: relative;
}

.no-svg .icon-list__item {
    padding: 0;
}

.icon-list__icon {
    fill: var(--peppermint-700);
    height: 1.5em;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.5em;
}

.no-svg .icon-list__icon {
    display: none;
}

.icon-list__item-text {
    margin-bottom: .75em;
}

.icon-list__link-wrapper {
    margin-bottom: 0;
}

.icon-list__headline {
    color: var(--peppermint-900);
}

@media screen and (max-width: 575px) {
    .icon-list--centered .icon-list__headline {
        text-align: center;
    }
}
