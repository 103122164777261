.teaser-news {
    flex-direction: column;
    line-height: 1.3;
    margin: 1em 0;
}

.teaser-news__date {
    font-size: .6rem;
}

.teaser-news__date::after {
    background: var(--peppermint-900);
    border-radius: 100%;
    content: "";
    display: inline-block;
    margin: 0 2px 2px 7px;
    padding: 3px;
}

.teaser-news__category {
    font-size: .7rem;
}

.teaser-news__title,
.teaser-news__title:link,
.teaser-news__title:visited,
.teaser-news__title:active {
    color: var(--gray-900);
    display: block;
    font-size: 1.625em;
    text-decoration: none;
}

.teaser-news__title:hover,
.teaser-news__title:focus,
.js.no-touchevents .teaser-news:hover .teaser-news__title {
    text-decoration: underline;
}
