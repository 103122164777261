.lazy-img-container {
    background-position: center;
    background-size: cover;
    height: auto;
    width: 100px;
}

.lazy-img-container__img {
    display: block;
    object-fit: cover;
    opacity: 0;
    transition: opacity .3s ease;
    width: 100%;

    &.is-loaded {
        opacity: 1;
    }
}
