.vcard {
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
}

.vcard__content {
    *:nth-last-child(1) {
        margin-bottom: 0;
    }
}

.vcard__image-wrapper {
    padding-bottom: 0;
    text-align: center;
}

.vcard__image {
    max-width: 13em;
    width: 100%;
}

.vcard__contact-detail {
    display: block;
}

.vcard__contact-detail--email > a {
    display: inline;
    word-wrap: break-word;
}

.vcard__external-link-hint {
    display: block;
    line-height: .95rem;
    margin-top: .1rem;
}

.vcard__booking-link {
    align-items: center;
    display: flex;
    gap: 6px;
    text-decoration: none;
}

.vcard__icon {
    fill: var(--peppermint-700);
    height: 14px;
    width: 14px;
}

.vcard--search-result {
    border: 0;
    margin-bottom: 15px;
}

.vcard--search-result .vcard__image-wrapper {
    padding: 0;
    text-align: left;
}

.vcard--search-result .vcard__content {
    padding: 0;
}

.vcard--search-result .vcard__image {
    max-width: 7em;
}

.vcard--search-result .vcard__address {
    margin-bottom: 0;
}

@media screen and (min-width: 420px) {
    .vcard--search-result .vcard__content {
        display: flex;
        flex-direction: column;
    }

    .vcard--search-result .vcard__image-wrapper {
        align-items: center;
        display: flex;
        padding-right: 1rem;
        width: auto;
    }

    .vcard__image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 33.33%;
    }

    .vcard__content {
        width: 66.66%;
    }

    .vcard {
        display: flex;
    }
}

@media screen and (max-width: 499px) {
    .vcard__image-wrapper {
        padding: 15px 0 15px 15px;
    }

    .vcard__content {
        padding: 15px;
    }
}

@media screen and (min-width: 500px) {
    .vcard__image-wrapper {
        padding: 30px 0 30px 30px;
    }

    .vcard__content {
        padding: 30px;
    }
}
