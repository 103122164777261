.search-form:target {
    /*
     * padding and margin for hash-bang navigation
     * see https://css-tricks.com/hash-tag-links-padding/#article-header-id-2
     * check reset on wider screens
     */
    margin-top: calc(-3.3rem + -2.5em);
    padding-top: 3.3rem;
}

.search-form .search-form__body {
    padding-bottom: 1.2em;
}

.search-form__input-column {
    position: relative;
}

.search-form__autocomplete-box {
    background: var(--white);
    border: 1px solid var(--gray-300);
    display: none;
    left: 0;
    padding: var(--module-padding-left-right);
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;

    &.is-active {
        display: block;
    }
}

.search-form__autocomplete-link {
    color: var(--gray-700);
    cursor: pointer;
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    transition: color 0.3s ease;

    &.is-active,
    &:focus,
    &:hover {
        color: var(--gray-900);
    }
}

.search-form__input[type=search] {
    background: var(--gray-50);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    font-size: 18px;
    height: 48px;
    padding: 8px 55px 8px 16px;
    transition: border-color .3s ease;

    &:focus,
    &:hover {
        border-color: var(--peppermint-700);
        outline: 0;
    }

    &::placeholder {
        color: var(--gray-400);
        font-size: 18px;
    }
}

.search-form__button[type=submit] {
    align-items: center;
    background: 0;
    border: 0;
    display: flex;
    height: 48px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
}

.search-form__button-icon {
    fill: var(--gray-900);
    height: 14px;
    width: 14px;
}

@media screen and (min-width: 660px) {
    .search-form:target {
        /*
         * revert hash-bang navigation tweak. see mobile styles
         */
        margin-top: -2.5em;
        padding-top: 2.5em;
    }

    .search-form .search-form__body {
        padding-bottom: 2.25em;
    }

    .search-form__input-column {
        padding: 0;
    }

    .search-form__input-column {
        flex: 1 1 100%;
    }
}
