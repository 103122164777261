.quote__body {
    position: relative;
}

.quote__wrapper {
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-radius: var(--module-border-radius);
    padding: 1.7rem;
}

.quote__blockquote {
    margin: 0;
}

.quote__text::before {
    content: open-quote;
}

.quote__text::after {
    content: close-quote;
}

.quote__text {
    color: var(--peppermint-700);
    font-size: 24px;
    line-height: 1.3;
    margin: 0 0 7px;
    quotes: "\201E" "\201C" "\201E" "\201C";
}

.quote__footer {
    color: var(--peppermint-400);
    line-height: 1.4;
}

.quote__meta {
    margin: 0;
}

.quote__meta--publication::before {
    content: "\2013\A0";
}

.quote__source-link,
.quote__source-link:visited {
    text-decoration: none;
}

.quote__picture {
    left: 0;
    max-width: 320px;
    min-width: 200px;
    position: absolute;
    top: 0;
    width: 80%;
}

.quote--picture .quote__body {
    padding: 25% 0 0 1.1rem;
}

.quote--picture .image__img {
    border-radius: 20px;
}

.quote--picture .quote__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: calc(30% + 1.7rem);
}

/* at 450px the picture has the full width */
@media screen and (min-width: 450px) {
    .quote--picture .quote__body {
        padding: 6.2rem 0 0 1.1rem;
    }

    .quote--picture .quote__wrapper {
        padding-top: calc(6.2rem + 30px);
    }
}

/* switch to the vertical design */
@media screen and (min-width: 800px) {
    .quote__picture {
        width: 200px;
    }

    .quote--picture .quote__body {
        padding: 2.8rem 0 0 100px; /* padding-left = half-image-width */
    }

    .quote--picture .quote__wrapper {
        padding-left: 7rem;
        padding-top: 1.7rem;
    }
}

@media screen and (min-width: 1000px) {
    .quote__picture {
        width: 33.33%;
    }

    .quote--picture .quote__body {
        padding-left: 16.666%;
    }

    .quote--picture .quote__wrapper {
        padding-left: calc(16.6666% + 3rem);
    }
}
