/**
 * link-stack
 */

.link-stack {
    @extend .u-list-style-none;
}

.link-stack__item {
    border-top: 1px solid var(--grey-medium);
    padding: 1px 0;
}

.link-stack__item:first-child {
    border-top: 0;
}

.link-stack__link {
    display: block;
    padding: .5em 1em;
    position: relative;
}

.link-stack__link,
.link-stack__link:visited,
.link-stack__link:focus,
.link-stack__link:hover,
.link-stack__link:active {
    text-decoration: none;
}

.link-stack__link:focus,
.link-stack__link:hover,
.link-stack__link:active {
    background-color: var(--scale);
}

/*
 * link-stack with icon
 */

.link-stack--icon .link-stack__link {
    padding: .5em 1em .5em 2em;
}

.no-svg .link-stack--icon .link-stack__link {
    padding: .5em 1em;
}

.link-stack__icon {
    bottom: 0;
    left: 0;
    margin: auto auto auto .5em;
    position: absolute;
    top: 0;
    width: 1em;
}
