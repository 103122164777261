/**
 * badge
 */

.badge {
    text-align: left;
}

@media screen and (min-width: 36rem) {
    .badge {
        text-align: center;
    }

    .badge .image__img {
        margin: auto;
    }
}
